import React from "react"
import Layout from "../../Layout"
import Meta from "../../components/Meta"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import Spacer from "../../components/Spacer"
import StaticHeadline from "../../components/static/StaticHeadline"
import StaticParagraph from "../../components/static/StaticParagraph"
import StaticContainer from "../../components/static/StaticContainer"
import StaticImage from "../../components/static/StaticImage"

import usePagesImages from "../../hooks/usePagesImages"
import StaticOl from "../../components/static/StaticOl"
import StaticUl from "../../components/static/StaticUl"

export default function BenzinpreisVerlaufEntwicklung({ data }) {
  const fluid = usePagesImages("warum-gehen-benzinpreise-hoch.jpg")

  return (
    <Layout>
      <Meta
        title="Warum gehen die Benzinpreise hoch"
        description="Hohe Benzinpreise überall: Tankchecker erklärt, welche Faktoren den Benzinpreis beeinflussen und warum die Spritpreise 2021 wieder stark angestiegen sind."
        image={fluid.src}
      />
      <Header />
      <main>
        <StaticContainer maxWidth="lg">
          <Spacer />
          <StaticHeadline variant="h1" align="center" gutterBottom>
            Spritpreise: Durchschnittlicher Benzinpreis-Verlauf und Entwicklung
          </StaticHeadline>
          <StaticImage
            fluid={fluid}
            alt="Spritpreise: Durchschnittlicher Benzinpreis-Verlauf und Entwicklung"
            copyright="Bildnachweis: hohl / iStock"
          />
          <StaticParagraph gutterBottom>
            Es gibt vielfältige Faktoren, warum es zu Senkungen oder Erhöhungen
            der Benzinpreise kommt. Die größten Tankstellenmarken sind hier in
            Deutschland Aral (21 Prozent aller Tankstellen in Deutschland in
            2020), gefolgt von Shell und Total. Diese drei Ölgesellschaften
            machen über ein Drittel (ca. 38 Prozent) des gesamten
            Tankstellennetzes in Deutschland aus.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Allerdings können die Tankstellenbetreiber nicht „einfach so“ eine
            Erhöhung oder Senkung der Benzinpreise bestimmen. Ob der Preis an
            der Zapfsäule gesenkt oder erhöht wird, hängt vielmehr von mehreren,
            zum großen Teil nicht beeinflussbaren Faktoren ab, wie
            beispielsweise:
          </StaticParagraph>
          <StaticOl>
            <li>
              <b>Der Rohölpreis:</b> Steigt der Rohölpreis an der Börse in
              Rotterdam, so hat dies natürlich auch Folgen für den Benzinpreis
              an der Zapfsäule. Steigende Ölpreise bedeuten fast immer steigende
              Benzinpreise.
            </li>
            <li>
              Der aktuelle Dollarkurs: Da Rohöl fast ausschließlich in Dollar
              gehandelt wird, haben Kursschwankungen ebenfalls einen Einfluss
              auf den Benzinpreis.
            </li>
            <li>
              <b>
                Klimatische und wirtschaftspolitische Ereignisse in den
                erdölproduzierenden Ländern:
              </b>
              Ein Großteil des globalen Erdöls kommt aus Gegenden, die nicht
              unbedingt zu den politisch stabilsten Regionen der Erde zählen.
              Venezuela, Saudi-Arabien, Kanada, Iran, Irak, Russland, Kuweit,
              Vereinigte Arabische Emirate, USA, und Libyen: Das sind die Länder
              mit den weltweit größten Erdölvorkommen. Deutschland bezieht sein
              Erdöl vorwiegend aus Russland, Großbritannien, Norwegen,
              Kasachstan und Libyen. Kommt es nun in einem der wichtigen
              erdölfördernden Staaten zu politischen Krisen oder
              Umweltkatastrophen, so wirkt sich die verminderte Förderung auch
              auf den Preis an den Handelsbörsen aus. Die Kraftstoffpreise
              steigen daraufhin an.
            </li>
            <li>
              <b>Der Deckungsbeitrag:</b> Die Mineralölkonzerne haben Transport-
              und Logistikkosten. Irgendwie muss das gekaufte Rohöl ja die
              Raffinerien erreichen, wird dort veredelt und gereinigt und dann
              an das Tankstellennetz verteilt. Zusätzliche Kosten entstehen für
              die Lagerhaltung, den Vertrieb, die Verwaltung und die Forschung.
              Und schlussendlich möchten die Mineralölkonzerne und die
              Raffinerien ja Gewinne erwirtschaften. Möglichst hohe Gewinne.
            </li>
          </StaticOl>
          <StaticParagraph gutterBottom>
            Die Steuerabgaben, die hier in Deutschland satte Dreiviertel des
            Gesamtpreises pro Liter verkauftem Kraftstoff ausmachen, spielen bei
            der Anpassung von Benzinpreisen übrigens keine Rolle. Denn die
            bleiben immer gleich pro Liter. Und die Mehrwertsteuer, die zum
            Schluss noch auf die Gesamtliteranzahl hinzuberechnet wird, besteht
            ebenfalls aus einem festen Prozentsatz (seit 2007 zahlen wir 19
            Prozent auf Kraftstoffe).
          </StaticParagraph>
          <StaticHeadline variant="h2" gutterBottom>
            Wann steigen die Spritpreise und wann sinken sie?
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            In den letzten Jahren ist zu beobachten, dass die Preisverteilung
            über den Tag gesehen immer wieder aus diversen Erhöhungen und
            Senkungen der Spritpreise besteht. Aber gibt es einen Trend, wann
            genau die Benzinpreise wieder höher steigen und wann sie sinken?
            Wenn man die Erhöhungen und Senkungen der Preise über den Tag
            verteilt beobachtet, lässt sich zumindest feststellen, dass Tanken
            am Morgen zwischen 5 und 9 Uhr am teuersten ist, und sich im Mittel
            über den Tag verteilt immer weiter senkt, um dann schließlich gegen
            Abend, so zwischen 19 und 21 Uhr, am günstigsten zu sein.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Viele Tankstellen schließen gegen 22 oder 23 Uhr, sodass die
            verbliebenen Tankstellen dann über Nacht ihre Preise wieder stark
            erhöhen.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Generell ist es ratsam, die wirtschaftspolitischen Ereignisse gerade
            in den OPEC-Ländern im Blick zu behalten. Regelmäßige Ansichten der
            Wochendiagramme zu den Entwicklungen der Benzinpreise geben
            zusätzliche Hinweise zu günstigen Zeitfenstern, in denen die
            Spritpreise an Deiner Tankstelle wahrscheinlich sinken.
          </StaticParagraph>
          <StaticHeadline variant="h2" gutterBottom>
            Warum sind 2021 die Benzinpreise wieder so hoch?
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Seit Anfang 2021 staunen alle Tankstellenbesucher: Steigende
            Benzinpreise, wohin man auch guckt. Egal, ob Benzin, Diesel, Super
            E10 oder Super E5, alle Kraftstoffsorten sind seit dem 1. Januar im
            preislichen Aufwärtstrend. Für die hohen Benzinpreise gibt es
            mehrere Gründe, einige konnte man sogar im Vorfeld bereits
            voraussagen:
          </StaticParagraph>
          <StaticUl>
            <li>
              Die im letzten Sommer aufgrund von Corona beschlossene
              Mehrwertsteuersenkung auf 16 Prozent endete Ende 2020 wieder. Seit
              dem 1.1.2021 zahlen also alle Benzinkäufer an den Tankstellen
              wieder den alten, normalen Mehrwertsteuersatz von nun wieder 19
              Prozent.
            </li>
            <li>
              Seit Jahresanfang gibt es eine weitere Steuer, die in dem großen
              Anteil von Steuerabgaben pro Liter Benzin hinzugerechnet wird: die
              CO2-Steuer. Und die schlägt richtig zu Buche. Rund 7 Cent kostet
              der Liter Benzin mehr, für Diesel müssen sogar ca. 8 Cent/Liter
              mehr gezahlt werden.
            </li>
            <li>
              Die Aussicht auf ein Ende der Corona-Pandemie rückt durch die seit
              Ende 2020 anlaufenden Impfungen näher. Aufgrund des erwarteten
              Konjunkturaufschwunges stieg der Ölpreis seit Ende 2020
              kontinuierlich und erreichte im März 2021 sogar sein vorläufiges
              Hoch. Im Prinzip erholte sich der Benzinpreis aber nur und stieg
              wieder auf das Vorjahresniveau, bevor die Covid-19-Pandemie sich
              ausbreitete.
            </li>
            <li>
              Die Organisation erdölexportierender Länder (OPEC) hatte bereits
              im vergangenen Jahr beschlossen, bis April 2022 die Menge an
              geförderten Rohöl zu reduzieren. Auch die gesunkenen
              Öl-Lagerbestände in den USA haben Einfluss auf die steigenden
              Benzinpreise.
            </li>
            <li>
              Die starke Konjunkturerholung in Asien führte zu einer erhöhten
              Nachfrage an Rohstoffen, unter anderem Erdöl.
            </li>
          </StaticUl>
          <StaticParagraph gutterBottom>
            Alle diese Faktoren trugen dazu bei, dass die Benzinpreise wieder
            deutlich stiegen. Wie hoch die Benzinpreise noch steigen, lässt sich
            nicht voraussagen. Eine deutliche Senkung ist zumindest nicht zu
            erwarten. Wenn Du also möglichst günstig tanken willst, behalte am
            besten über Onlineportale oder eine App die Benzinpreise in Deiner
            Nähe im Auge. Vergleiche die Tagesentwicklung der Benzinpreise über
            einige Zeit, dann bekommst Du schnell einen guten Eindruck, wann es
            sich für Dich und Deinen Geldbeutel lohnt, an die Tankstelle zu
            fahren und wieder aufzutanken.
          </StaticParagraph>

          <Spacer />
          <Spacer />
        </StaticContainer>
      </main>
      <Footer />
    </Layout>
  )
}
